import styles from "./PanelNumberSelector.module.css";
import { InputNumber } from "antd";

// A functional component that displays a number and allows a user to increment or decrement it using the context api
export default function PanelNumberSelector(props: {
  count: number;
  handleChange: (count: number | null) => void;
  label: string;
}) {
  return (
    <div className={styles.container}>
      <span className={styles.label}>{props.label}</span>
      <InputNumber
        min={1}
        max={10}
        defaultValue={props.count}
        onChange={props.handleChange}
      />
    </div>
  );
}
