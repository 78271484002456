import React, { useEffect, useState } from "react";
import "./App.css";
import { Col, Layout, PageHeader, Row, Tooltip } from "antd";
import PanelNumberSelector from "./components/PanelNumberSelector/PanelNumberSelector";
import Panel from "./types/Panel";
import PanelDetails from "./components/PanelDetails/PanelDetails";
import CostPane from "./components/CostPane/CostPane";
import Prices from "./data/Prices";
import TypeSelector, {
  CommissionType,
} from "./components/TypeSelector/TypeSelector";
import TypeDescription from "./components/TypeDescription/TypeDescription";

function App() {
  const { Footer, Content } = Layout;

  const [commissionType, setCommissionType] = useState(CommissionType.PINUP);
  const [panelCount, setPanelCount] = useState(1);
  const [panelData, setPanelData] = useState<Panel[]>([]);

  function handlePanelCountChange(count: number | null) {
    if (count) {
      setPanelCount(count);
    }
  }

  function handlePanelDataChange(panel: Panel) {
    const newPanelData = panelData.map((p) => {
      if (p.id === panel.id) {
        return panel;
      }
      return p;
    });
    setPanelData(newPanelData);
  }

  function handleTypeChange(type: CommissionType) {
    if (type === CommissionType.PINUP) {
      setPanelCount(1);
    }
    setCommissionType(type);
  }

  useEffect(() => {
    const panels: Panel[] = [];
    for (let i = 0; i < panelCount; i++) {
      panels.push({
        id: i,
        characterCount: 1,
        lineart: false,
        shaded: false,
      });
    }
    setPanelData(panels);
  }, [panelCount]);

  return (
    <>
      <Layout>
        <PageHeader title="Nephi's Commission Calculator" />
        <Content className={"appContent"}>
          <Row>
            <Col span={18}>
              <TypeSelector handleChange={handleTypeChange} />
              <TypeDescription type={commissionType} />
              {commissionType === CommissionType.COMIC ||
              commissionType === CommissionType.STICKER ? (
                <PanelNumberSelector
                  handleChange={handlePanelCountChange}
                  count={panelCount}
                  label={`Number of ${
                    commissionType === CommissionType.COMIC
                      ? "Panel"
                      : commissionType
                  }s:`}
                />
              ) : null}
              {[CommissionType.COMIC, CommissionType.PINUP].includes(
                commissionType
              )
                ? panelData.map((panel) => {
                    return (
                      <PanelDetails
                        key={panel.id}
                        panel={panel}
                        handleChange={handlePanelDataChange}
                        type={commissionType}
                      />
                    );
                  })
                : null}
            </Col>
            <Col span={6}>
              <CostPane
                panels={panelData}
                prices={Prices}
                type={commissionType}
              />
            </Col>
          </Row>
        </Content>
        <Footer>
          Good to go? Contact Nephi via{" "}
          <a href="https://twitter.com/Nephrited">Twitter</a> or{" "}
          <Tooltip title="Add me @ Nephrited#0001!">
            <a>Discord</a>
          </Tooltip>
        </Footer>
      </Layout>
    </>
  );
}

export default App;
