import { CommissionType } from "../TypeSelector/TypeSelector";
import styles from "./TypeDescription.module.css";

export default function TypeDescription(props: { type: CommissionType }) {
  const descriptions = {
    [CommissionType.PINUP]: "A single panel image with up to 3 characters.",
    [CommissionType.COMIC]:
      "A comic of up to 10 panels with up to 3 characters per panel. Individual panels can have different detail levels.",
    [CommissionType.STICKER]:
      "A non-animated sticker, usually of a characters head and bust. Discounts apply at 5 or more stickers.",
    [CommissionType.ANIMATED_STICKER]:
      "A sticker with a short animation loop - contact Nephi directly to discuss these, as prices vary.",
  };

  return <div className={styles.container}>{descriptions[props.type]}</div>;
}
