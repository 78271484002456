export interface IPrices {
  lineart: number;
  shaded: number;
  character: number;
  sticker: number;
  discounts: {
    tenStickers: number;
    fiveStickers: number;
  };
}

const Prices: IPrices = {
  lineart: 10,
  shaded: 10,
  character: 22,
  sticker: 30,
  discounts: { tenStickers: 0.85, fiveStickers: 0.9 },
};

export default Prices;
