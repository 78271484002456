import styles from "./CostPane.module.css";
import Panel from "../../types/Panel";
import { IPrices } from "../../data/Prices";
import { CommissionType } from "../TypeSelector/TypeSelector";

export default function CostPane(props: {
  panels: Panel[];
  prices: IPrices;
  type: CommissionType;
}) {
  function calculateTotal() {
    let total = 0;
    if ([CommissionType.PINUP, CommissionType.COMIC].includes(props.type)) {
      props.panels.forEach((panel) => {
        total += panel.characterCount * props.prices.character;
        if (panel.lineart) {
          total += props.prices.lineart;
        }
        if (panel.shaded) {
          total += props.prices.shaded;
        }
      });
    } else if (props.type === CommissionType.STICKER) {
      total += props.panels.length * props.prices.sticker;
      if (props.panels.length >= 10) {
        total = total * props.prices.discounts.tenStickers;
      } else if (props.panels.length >= 5) {
        total = total * props.prices.discounts.fiveStickers;
      }
    }
    return total;
  }

  function comicDisplay() {
    return props.panels.map((panel) => {
      return (
        <div className={styles.panelCost} key={panel.id}>
          {props.type === CommissionType.PINUP ? null : (
            <h4>Panel {panel.id + 1}</h4>
          )}
          <ul>
            <li>
              Characters: {panel.characterCount} -{" "}
              {panel.characterCount * props.prices.character}
            </li>
            <li>
              Lineart: {panel.lineart ? "Yes" : "No"} -{" "}
              {panel.lineart ? props.prices.lineart : 0}
            </li>
            <li>
              Shading: {panel.shaded ? "Yes" : "No"} -{" "}
              {panel.shaded ? props.prices.shaded : 0}
            </li>
          </ul>
        </div>
      );
    });
  }

  function stickerDisplay() {
    let discountText = "";
    if (props.panels.length >= 10) {
      discountText = `(${
        100 - props.prices.discounts.tenStickers * 100
      }% discount)`;
    } else if (props.panels.length >= 5) {
      discountText = `(${
        100 - props.prices.discounts.fiveStickers * 100
      }% discount)`;
    }
    return (
      <>
        <div className={styles.panelCost}>
          <ul>
            <li>Stickers: {props.panels.length}</li>
          </ul>
        </div>
        <div>{discountText}</div>
      </>
    );
  }

  return (
    <div className={styles.container}>
      {props.type === CommissionType.ANIMATED_STICKER ? null : (
        <>
          <h2>Total Cost</h2>
          {props.type === CommissionType.STICKER ? (
            <>{stickerDisplay()}</>
          ) : (
            <>{comicDisplay()}</>
          )}
          <div className={styles.total}>Total: £{calculateTotal()}</div>
          <div className={styles.convert}>
            <>
              (click here for{" "}
              <a
                href={`https://www.google.com/search?q=${calculateTotal()}GBP+to+USD`}
              >
                USD
              </a>{" "}
              /{" "}
              <a
                href={`https://www.google.com/search?q=${calculateTotal()}GBP+to+EUR`}
              >
                EUR
              </a>
              )
            </>
          </div>
        </>
      )}
    </div>
  );
}
