import styles from "./PanelDetails.module.css";
import Panel from "../../types/Panel";
import { InputNumber, Switch } from "antd";
import { CommissionType } from "../TypeSelector/TypeSelector";

export default function PanelDetails(props: {
  panel: Panel;
  handleChange: (panel: Panel) => void;
  type: CommissionType;
}) {
  function updatePanelCharacterCount(count: number | null) {
    if (count) {
      const panel = props.panel;
      panel.characterCount = count;
      props.handleChange(panel);
    }
  }

  function updatePanelLineQuality(quality: boolean) {
    const panel = props.panel;
    panel.lineart = quality;
    props.handleChange(panel);
  }

  function updatePanelColorDepth(shaded: boolean) {
    const panel = props.panel;
    panel.shaded = shaded;
    props.handleChange(panel);
  }

  function titleText() {
    switch (props.type) {
      case CommissionType.PINUP:
        return "";
      case CommissionType.COMIC:
        return "Panel " + (props.panel.id + 1);
      default:
        return "";
    }
  }

  return (
    <div className={styles.container}>
      <h1>{titleText()}</h1>
      <div className={styles.formContainer}>
        <div className={styles.formItem}>
          Number of Characters:
          <InputNumber
            min={1}
            max={3}
            defaultValue={1}
            onChange={updatePanelCharacterCount}
          />
        </div>
        <div className={styles.formItem}>
          Line Quality:
          <Switch
            checkedChildren={"Lineart"}
            unCheckedChildren={"Sketch"}
            defaultChecked={false}
            onChange={updatePanelLineQuality}
          />
        </div>
        <div className={styles.formItem}>
          Color Depth:
          <Switch
            checkedChildren={"Shaded"}
            unCheckedChildren={"Flat"}
            defaultChecked={false}
            onChange={updatePanelColorDepth}
          />
        </div>
      </div>
    </div>
  );
}
