import styles from "./TypeSelector.module.css";
import { Select } from "antd";
import { useState } from "react";

export enum CommissionType {
  PINUP = "Pinup",
  COMIC = "Comic",
  STICKER = "Sticker",
  ANIMATED_STICKER = "Animated Sticker",
}

export default function TypeSelector(props: {
  handleChange: (type: CommissionType) => void;
}) {
  const [currentValue, setCurrentValue] = useState(CommissionType.PINUP);

  function handleSelectChange(value: CommissionType) {
    setCurrentValue(value);
    props.handleChange(value);
  }

  return (
    <div className={styles.container}>
      <span className={styles.label}>Commission Type:</span>
      <Select
        onSelect={handleSelectChange}
        value={currentValue}
        className={styles.dropdown}
      >
        {Object.values(CommissionType).map((type) => {
          return (
            <Select.Option key={type} value={type}>
              {type}
            </Select.Option>
          );
        })}
      </Select>
    </div>
  );
}
